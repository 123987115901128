<i18n locale="en" lang="yaml">
page.title : "Create Company"
page.description : "Create new company to system"
create_company.success : "Create New Company {name} successful"
</i18n>
<i18n locale="th" lang="yaml" >
page.title : "เพิ่มบริษัทในระบบ"
page.description : "เพิ่มข้อมูลบริษัทใหม่ในระบบ"
create_company.success : "เพิ่มบริษัท {name} ในระบบเรียบร้อย"
company_create.submit.label : "เพิ่มบริษัทใหม่"
</i18n>

<template>
	<div class="page page-padding">
		<my-page-header
			:title="$t('page.title')"
			:description="$t('page.description')"/>

		<a-card :body-style="{ padding: '24px 32px' }" :bordered="false">
			<CompanyForm
				ref="formRef"
				:loading="loading"
				mode="create"
				:company-types="createTypes"
				:all-types="allTypes"
				@submit="handleSubmit">
				<template v-slot:submitSlot="{ formLoading }">
					<a-button
						:loading="formLoading"
						type="info"
						html-type="submit"
						class="btn-submit"
						icon="edit"
						size="large">
						{{$t('company_create.submit.label')}}
					</a-button>
				</template>
			</CompanyForm>
		</a-card>
	</div>
</template>

<script>
import PageMixin from '@mixins/PageMixin.vue'
import CompanyForm from '@components/company/CompanyForm.vue'
import axios from "axios"
export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components : {
		CompanyForm
	} ,
	mixins: [PageMixin],
	data(){
		return {
			company : {} ,
			loading : false,
			createTypes : [],
			allTypes : [],
		}
	} ,
	mounted() {
		this.fetchCompanyTypes();
	} ,
	methods : {
		handleSubmit(formData) {
			this.loading = true
			axios.post("/api/companies/create",formData).then((response) => {
				const newCompany = response.data.data.company
				this.$router.push({
					name : 'company/view',
					params : {id : newCompany.id}
				});
				this.$message.success(this.$t('create_company.success',{"name" : newCompany.name}))
			}).catch((error) => {
				this.$refs.formRef.formErrors(error)
			}).finally(()=>{
				this.loading = false;
			});
		} ,
		fetchCompanyTypes() {
			this.loading = true
			const extDealer = 5
			const servCenter = 6
			axios.get("/api/companies/types").then((response) => {
				this.allTypes = response.data.data.dataset
				this.createTypes = this.allTypes.filter((type) => type.id === extDealer || type.id === servCenter)
															.map((creatable) => creatable.name)
			}).catch((error) => {
				this.fetchError(error)
			}).finally(() => {
				this.loading = false
			})
		}
	} ,

}
</script>
